<template>
  <div>
    <h4 class="mb-4"><strong>El número no es Virgin Mobile</strong></h4>
    <p></p>

    <a href="https://www.virginmobile.cl/planes" class="btn btn-primary" type="button">
      Ir a Portabilidad
    </a>

  </div>
</template>

<script>
export default {
  name: 'PaymentNotTariff',

  setup() {
    return {
      error: {
        msisdn: false
      },
      msisdn: '',
    }
  },
}
</script>

<style scoped>
</style>
